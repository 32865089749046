define('tm-common/models/matter-type', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        prefix: attr('string'),
        name: attr('string'),
        description: attr('string'),
        active: attr('number'),
        tasking: attr('string'),

        newSubjectsArePublic: attr('number'),
        requireCaseDate: attr('number'),

        // not on the backend, only for show on the UI
        numberOfTaskTemplates: attr('number'),

        caseCategory: attr('string'),

        fieldTemplate: belongsTo('field-template', { async: false }),

        showJuryProjectInDashboard: attr('number'),

        updateFieldDefaults: (0, _emberApiActions.memberAction)({
            path: 'update-field-defaults',
            type: 'post'
        }),

        nameAndPrefix: Ember.computed('name', 'prefix', function () {
            var _this = this;

            var result = [];

            ['prefix', 'name'].forEach(function (item) {
                var value = _this.get(item);

                if (value) {
                    result.push(value);
                }
            });

            return result.join(' - ');
        }),

        nameAndPrefixAndDescription: Ember.computed('nameAndPrefix', 'description', function () {
            var _this2 = this;

            var result = [];

            ['nameAndPrefix', 'description'].forEach(function (item) {
                var value = _this2.get(item);

                if (value) {
                    result.push(value);
                }
            });

            return result.join(' - ');
        }),

        setMatterTypeConfiguration: (0, _emberApiActions.memberAction)({
            path: 'set_config',
            type: 'post'
        }),
        getMatterTypeConfiguration: (0, _emberApiActions.memberAction)({
            path: 'get_config',
            type: 'get'
        })
    });
});