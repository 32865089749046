define('tm-common/models/employee', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        modelName: 'employee',

        department: attr('string'),
        role: attr('string'),
        rate: attr('number'),
        weeklyHours: attr('number'),
        expectedHours: attr('number'),
        yearlyExpectedHours: attr('number'),
        laborCosts: attr('number'),
        employeeNumber: attr('string'),
        juryBonusAmount: attr('number'),
        bonusThreshold: attr('number'),
        standardBonusAmount: attr('number'),
        billingName: attr('string'),
        standardBonusCreditRate: attr('number'),
        includeInScheduling: attr('number'),

        // user fields
        firstName: attr('string'),
        middleName: attr('string'),
        lastName: attr('string'),
        email: attr('string'),
        userName: attr('string'),
        password: attr('string'),
        salt: attr('string'),
        passwordExpired: attr('string'),
        status: attr('string'),
        lastLogin: attr('string'),
        lastLogout: attr('string'),
        tmNum: attr('number'),
        tmType: attr('string'),
        salesforceId: attr('string'),
        token: attr('string'),
        tokenExpires: attr('string'),
        avatarId: attr('number'),

        // relationships
        tasks: hasMany('task', { inverse: 'assignedTo' }),
        userAddrs: hasMany('user-addr', {
            async: false
        }),
        userNumbers: hasMany('user-number', {
            async: false
        }),
        user: belongsTo('user', { async: true, inverse: 'employee' }),
        updatedBys: hasMany('billable-group', { async: true, inverse: 'updatedBy' }),
        createdBys: hasMany('billable-group', { async: true, inverse: 'createdBy' }),
        approvedBys: hasMany('billable-group', {
            async: true,
            inverse: 'approvedBy'
        }),

        // calculated
        fullName: Ember.computed('firstName', 'lastName', function () {
            var fullName = this.get('firstName') + ' ' + this.get('lastName');

            return Ember.$('<div/>').html(fullName).text();
        }),

        actualFullName: Ember.computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName') + ' ';
        }),

        shortName: Ember.computed('firstName', 'lastName', function () {
            var first = this.get('firstName') || '';
            var last = this.get('lastName') || '';

            return first + ' ' + last.substring(0, 1);
        }),

        isActive: Ember.computed.equal('status', 'Active'),

        toString: function toString() {
            return this.get('id');
        }
    });
});